import qs from "qs";
import request from "../commons/request";
const AUTH_PREFIX =
  "https://api-brain.itic-sci.com/kg-industry/chatsearch/industry-api-test";

const getUUID = () => {
  let local = localStorage.getItem("requestUUID");
  if (local) {
    return local;
  } else {
    let id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    localStorage.setItem("requestUUID", id);

    return id;
  }
};

export function graphData(id = "cells_and_genes") {
  return request({
    url: `/open-api/industry/graph?project_id=${id}`,
    method: "get",
  });
}
// 检测是否在升级
export function testUpdate() {
  return request({
    url: `https://api-brain.itic-sci.com/kg-industry/chatsearch/industry-api-test/`,
    method: "get",
  });
}
// // 多轮对话接口
export async function queryDataNew(question, sessionID, config = {}) {
  let tokenType = sessionStorage.getItem("token_type");
  let accessToken = sessionStorage.getItem("access_token");
  try {
    let res = await request({
      ...config,
      url: "https://llm.itic-sci.com/paper_link//chat",
      method: "post",
      data: {
        query: question,
        sess_id: sessionID,
      },
      headers: {
        Authorization: `${tokenType} ${accessToken}`,
      },
    });

    if (!res.sess_id) {
      return false;
    }

    return res;
  } catch (error) {
    if (error.code !== "ERR_NETWORK") {
      getChatRecord({
        chat_res: null,
        sess_id: sessionID,
        chat_req: {
          query: question,
        },
        seq_no: 0,
        status_code: error.code,
      });
    }

    return error;
  }
}

export async function queryRegen(sessionID, config = {}) {
  let tokenType = sessionStorage.getItem("token_type");
  let accessToken = sessionStorage.getItem("access_token");
  try {
    let res = await request({
      ...config,
      url: "https://llm.itic-sci.com/paper_link//regen",
      method: "post",
      data: {
        sess_id: sessionID,
      },
      headers: {
        Authorization: `${tokenType} ${accessToken}`,
      },
    });

    if (!res.sess_id) {
      return false;
    }

    return res;
  } catch (error) {
    console.log(error);
  }
}

// 多轮对话接口
// export function queryDataNew(question, sessionID, config = {}) {
//   let tokenType = sessionStorage.getItem("token_type");
//   let accessToken = sessionStorage.getItem("access_token");
//   return request({
//     ...config,
//     url: "https://llm.itic-sci.com/paper_link//chat",
//     method: "post",
//     data: {
//       query: question,
//       sess_id: sessionID,
//     },
//     headers: {
//       Authorization: `${tokenType} ${accessToken}`,
//     },
//   });
// }

export function getTreeData(id = "cells_and_genes") {
  return request({
    url: `/open-api/industry/tree?project_id=${id}`,
    method: "get",
  });
}

export function getFieldGraphData(id = "cells_and_genes") {
  return request({
    url: `/open-api/industry/field-graph?project_id=${id}`,
    method: "get",
  });
}

export function getNodeSubGraph(params = {}) {
  const {
    node_id = "1",
    node_type = "field",
    project_id = "cells_and_genes",
  } = params;
  return request({
    url: `/open-api/industry/node-subgraph?project_id=${project_id}&node_id=${node_id}&node_type=${node_type}`,
    method: "get",
  });
}

export function getSubGraphData(params = {}) {
  const {
    node_id = "",
    node_name = "细胞与基因",
    search_type = "",
    node_layer = 0,
    project_id = "cells_and_genes",
  } = params;

  return request({
    url: `/open-api/industry/node-subgraph?node_id=${node_id}&node_name=${node_name}&search_type=${search_type}&node_layer=${node_layer}&project_id=${project_id}`,
    method: "get",
  });
}

export function getLeftList(id = "cells_and_genes") {
  return request({
    url: `/open-api/industry/nodes?project_id=${id}`,
    method: "get",
  });
}

export function getChildNodes(id = "") {
  return request({
    url: `/open-api/industry/child_nodes?node_id=${id}&project_id=cells_and_genes`,
    method: "get",
  });
}

export function getPaperList(params) {
  const {
    project_id = "cells_and_genes",
    node_name = "细胞与基因",
    reverse = true,
    page = 1,
    size = 10,
    node_layer = 0,
    keyword = "",
    is_sz = 0,
    sort_field = "cite_num",
  } = params;
  return request({
    url: `/open-api/industry/paper-list?project_id=${project_id}&node_name=${node_name}&node_layer=${node_layer}&keyword=${keyword}&reverse=${reverse}&is_sz=${is_sz}&page=${page}&size=${size}&sort_field=${sort_field}`,
    method: "get",
  });
}

export function getChatDetailList(params) {
  const project_id = "cells_and_genes";

  return request({
    url: `${AUTH_PREFIX}/ai-api/industry_ai/query?project_id=${project_id}`,
    method: "post",
    data: params,
  });
}

/**
 *
 * @param {object} params
 * @param {string} params.project_id
 * @param {string} params.sort_field
 * @param {boolean} params.reverse
 * @param {string} params.keyword
 * @param {number} params.page
 * @param {number} params.size
 * @returns {Promise}
 */
export function getNewsList(params) {
  return request.get("/open-api/industry/news-list", {
    params,
  });
}

/**
 *
 * @param {string} news_id
 * @returns
 */
export function getNewsDetail(news_id) {
  return request.get("/open-api/industry/news-detail", {
    params: {
      news_id,
    },
  });
}

export function getPatentList(params) {
  const {
    project_id = "cells_and_genes",
    node_name = "细胞与基因",
    reverse = true,
    page = 1,
    size = 10,
    node_layer = 0,
    keyword = "",
    is_sz = 0,
    sort_field = "pub_date",
  } = params;
  return request({
    url: `/open-api/industry/patent-list?project_id=${project_id}&node_name=${node_name}&node_layer=${node_layer}&keyword=${keyword}&reverse=${reverse}&is_sz=${is_sz}&page=${page}&size=${size}&sort_field=${sort_field}`,
    method: "get",
  });
}
export function getScholarList(params) {
  const {
    project_id = "cells_and_genes",
    node_name = "细胞与基因",
    reverse = true,
    page = 1,
    size = 10,
    node_layer = 0,
    keyword = "",
    is_sz = 0,
    sort_field = "npubs",
  } = params;
  return request({
    url: `/open-api/industry/scholar-list?project_id=${project_id}&node_name=${node_name}&node_layer=${node_layer}&keyword=${keyword}&reverse=${reverse}&is_sz=${is_sz}&page=${page}&size=${size}&sort_field=${sort_field}`,
    method: "get",
  });
}

export function getIndustryList(params) {
  const {
    project_id = "cells_and_genes",
    node_name = "细胞与基因",
    reverse = true,
    page = 1,
    size = 10,
    node_layer = 0,
    keyword = "",
    is_sz = 0,
    sort_field = "n_papers",
  } = params;
  return request({
    url: `/open-api/industry/org-list?project_id=${project_id}&node_name=${node_name}&node_layer=${node_layer}&keyword=${keyword}&reverse=${reverse}&is_sz=${is_sz}&page=${page}&size=${size}&sort_field=${sort_field}`,
    method: "get",
  });
}

export function getCompanyList(params) {
  const {
    project_id = "cells_and_genes",
    province = "",
    opening_only = false,
    city = "",
    reverse = true,
    page = 1,
    size = 10,
    node_layer = 0,
    keyword = "",
    sort_field = "registered_capital",
  } = params;
  return request({
    url: `${AUTH_PREFIX}/open-api/industry/enterprise_list?project_id=${project_id}&province=${province}&keyword=${keyword}&reverse=${reverse}&city=${city}&page=${page}&size=${size}&sort_field=${sort_field}&opening_only=${opening_only}`,
    method: "get",
  });
}

export function getSearchkeyword(params) {
  const { keyword, search_type, project_id = "cells_and_genes" } = params;
  return request({
    url: `/open-api/industry/search-keyword?keyword=${keyword}&search_type=${search_type}&project_id=${project_id}`,
    method: "get",
  });
}

export function getNodeStats(node_name = "细胞与基因", id = "cells_and_genes") {
  return request({
    url: `/open-api/industry/node-stats?project_id=${id}&node_name=${node_name}`,
    method: "get",
  });
}

export function getPersonInterest(params) {
  return request({
    url: `https://api-brain.itic-sci.com/apiv2/n?a=__PersonInterest.Get___`,
    method: "post",
    data: params,
  });
}
export function getOrgInfo(params) {
  return request({
    url: `https://apiv2.aminersz.cn/magic?a=GetOrgInfo__orgapi.get___`,
    method: "post",
    data: params,
  });
}

export function getNodeDetail(params) {
  const { node_type, project_id = "cells_and_genes", data } = params;
  return request({
    url: `/open-api/industry/node-detail?node_type=${node_type}&project_id=${project_id}`,
    method: "post",
    data,
  });
}
export function getAiNodeDetail(params) {
  params.project_id = "cells_and_genes";
  return request({
    url: `${AUTH_PREFIX}/ai-api/industry_ai/entity_detail?${qs.stringify(
      params
    )}`,
    method: "get",
  });
}
export function getAiNodeStats(params) {
  return request({
    url: `${AUTH_PREFIX}/open-api/industry/node-stats?project_id=cells_and_genes&node_name=细胞与基因`,
    method: "get",
  });
}
export function getPromptWord(params) {
  return request({
    url: `${AUTH_PREFIX}/open-api/industry/recommend_word?project_id=cells_and_genes`,
    method: "get",
  });
}

export function getWarpLink(params) {
  return request({
    url: `https://itic-sci.com/gateway/download/wrap_link`,
    method: "post",
    data: params,
    withCredentials: true,
  });
}
export function getRelateList(params, id = "cells_and_genes") {
  const {
    node_id,
    source_node_type,
    size = 10,
    page = 1,
    target_node_type,
  } = params;

  return request({
    url: `/open-api/industry/relate-list?project_id=${id}&node_id=${node_id}&source_node_type=${source_node_type}&target_node_type=${target_node_type}&page=${page}&size=${size}`,
    method: "get",
  });
}

export function get(config, options) {
  return request({ ...config, method: "GET" }, options);
}

export function post(config, options) {
  return request({ ...config, method: "POST" }, options);
}

// 查询图谱节点
export function getGraph(data) {
  return request({
    url: `${AUTH_PREFIX}/ai-api/industry_ai/graph?${qs.stringify(data)}`,
    method: "get",
    // headers: {
    //   Authorization: getManageAuthorization(),
    // },
  });
}

export function getPaperData(params) {
  return request({
    url: `https://apiv2.aminersz.cn/magic?a=getPaperData__pub.GetPageData___`,
    method: "post",
    data: [
      {
        action: "pub.GetPageData",
        parameters: { ids: [params.id], include: ["bp"] },
        schema: {
          publication: [
            "id",
            "year",
            "title",
            "title_zh",
            "abstract",
            "abstract_zh",
            "authors",
            "authors._id",
            "authors.name",
            "authors.org",
            "authors.orgid",
            "authors.org_zh",
            "keywords",
            "authors.name_zh",
            "num_citation",
            "num_viewed",
            "num_starred",
            "num_upvoted",
            "is_starring",
            "is_upvoted",
            "is_downvoted",
            "venue.info.name",
            "venue.volume",
            "venue.info.name_zh",
            "venue.info.publisher",
            "venue.issue",
            "pages.start",
            "pages.end",
            "lang",
            "pdf",
            "ppt",
            "doi",
            "urls",
            "flags",
            "resources",
            "issn",
            "versions",
            "labels",
            "versions",
            "venue.info.id",
            "venue_hhb_id",
            "venue.info.type",
          ],
        },
      },
    ],
    isFormData: true,
  });
}

export function getProvinceTreeData() {
  return request({
    url: `https://api-brain.itic-sci.com/kg-industry/chatsearch/industry-api-test/open-api/industry/enterprise_area_tree?project_id=cells_and_genes`,
    method: "get",
  });
}
export function getChatLike(params) {
  const project_id = "cells_and_genes";

  return request({
    url: `${AUTH_PREFIX}/ai-api/industry_ai/like`,
    method: "post",
    data: { ...params, project_id, user_id: getUUID() },
  });
}
export function getChatDislike(params) {
  const project_id = "cells_and_genes";

  return request({
    url: `${AUTH_PREFIX}/ai-api/industry_ai/dislike`,
    method: "post",
    data: { ...params, project_id, user_id: getUUID() },
  });
}
export function getChatRecord(params) {
  const project_id = "cells_and_genes";

  return request({
    url: `https://api-brain.itic-sci.com/kg-industry/chatsearch/industry-api-test/ai-api/industry_ai/chat_record`,
    method: "post",
    data: { ...params, project_id, user_id: getUUID() },
  });
}
export function getChatNews(params) {
  const project_id = "cells_and_genes";

  return request({
    url: `https://industry.itic-sci.com/information/listInformation`,
    method: "post",
    data: {
      page: params.page || 1,
      size: 20,
      domainId: project_id === "cells_and_genes" ? "1" : "2",
      categoryId: null,
    },
  });
}
